import { render, staticRenderFns } from "./BlCategoryTree.vue?vue&type=template&id=33c1a14d&scoped=true&"
import script from "./BlCategoryTree.vue?vue&type=script&lang=js&"
export * from "./BlCategoryTree.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c1a14d",
  null
  
)

export default component.exports