<template>
  <div style="height: inherit">

    <!-- Шапка -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-12">
          <div class="ecommerce-header-items">

            <!-- Товаров найдено... -->
            <div class="result-toggler">
              <feather-icon
                  icon="MenuIcon"
                  class="d-block d-lg-none"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                Товаров найдено: {{ total }}
              </div>
              <div class="d-flex align-items-center ml-2" >
                <div>Кол-во на странице: </div>
                <v-select
                    v-model="pagination.perPage"
                    :options="pagination.perPageOptions"
                    :clearable="false"
                    class="ml-1"
                ></v-select>
              </div>

            </div>

            <!-- Блок кнопок -->
            <div class="view-options d-flex">

              <!-- Сртировка -->
              <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="sort.selected.text"
                  right
                  variant="outline-primary"
              >
                <b-dropdown-item
                    v-for="sortOption in sort.options"
                    :key="sortOption.value"
                    @click="sortProducts(sortOption)"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Вид отображения  -->
              <b-form-radio-group
                  v-model="itemView"
                  class="ml-1 list item-view-radio-group"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
              >
                <b-form-radio
                    v-for="option in itemViewOptions"
                    :key="option.value"
                    :value="option.value"
                >
                  <feather-icon
                      :icon="option.icon"
                      size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Оверлей -->
    <div class="body-content-overlay"/>

    <!-- Строка поиска -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
                v-model="searchModel"
                placeholder="Введите название"
                :disabled="this.dataLoading"
                class="search-product"
                @change="loadProducts(pagination.perPage, offset, sort.selected, filters, searchModel)"
            />
            <b-input-group-append is-text>
              <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>


    <b-overlay :show="dataLoading" rounded="sm">

      <!-- Товары -->
      <section :class="itemView">

        <!-- Карточка -->
        <b-card
            v-for="item in products"
            :key="item.product.id"
            class="ecommerce-card"
            no-body
        >

          <!-- Изображение -->
          <div class="item-img text-center product-img">
            <b-img
                :alt="`${item.product.product_code}-${item.product.product_name}`"
                class="card-img-top"
                :src="item.product.image"
            />
          </div>

          <!-- Карточка -->
          <b-card-body>

            <!-- Наименование -->
            <h6 class="item-name">
              <h6
                  class="text-body"
              >
                {{ item.product.name }}
              </h6>
            </h6>

            <!-- Grid Ценник -->
            <div class="item-wrapper">
              <h6 class="item-price text-right" v-if="itemView=='grid-view'">
                {{ item.price | formatNumber }}
              </h6>
            </div>

            <!-- Краткая сводка параметров -->
            <b-card-text class="item-description">
              <div class="row" v-if="nonempty(item.product.vendor_code)">
                <div class="col-12">Артикул: {{ item.product.vendor_code }}</div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span v-if="nonempty(item.product.power)">{{ item.product.power }}W</span>
                  <span v-if="nonempty(item.product.voltage)">{{ item.product.voltage }}V</span>
                  <span v-if="nonempty(item.product.grade_of_waterproofment)">{{ item.product.grade_of_waterproofment }}</span>

                </div>
              </div>
            </b-card-text>

            <!-- Краткая сводка параметров List -->
            <b-card-text class="item-description" v-if="itemView=='list-view'">
              <div class="row">
                <div class="col-12">
                  <div v-if="nonempty(item.product.brand)" class="row">
                    <div class="col-6 text-left">Бренд</div>
                    <div class="col-6 right">{{ item.product.brand }}</div>
                  </div>
                  <div v-if="nonempty(item.product.output_voltage)" class="row">
                    <div class="col-6 text-left">Выходное напряжение, В</div>
                    <div  class="col-6 right">{{ item.product.output_voltage }}</div>
                  </div>
                  <div v-if="nonempty(item.product.output_current)" class="row">
                    <div class="col-6 text-left">Выходной ток, А</div>
                    <div class="col-6 right">{{ item.product.output_current }}</div>
                  </div>
                  <div v-if="nonempty(item.product.power)" class="row">
                    <div class="col-6 text-left">Мощность, Вт</div>
                    <div class="col-6 right">{{ item.product.power }}</div>
                  </div>

                  <div v-if="nonempty(item.product.voltage)" class="row">
                    <div class="col-6 text-left">Рабочее напряжение, В</div>
                    <div class="col-6 right">{{ item.product.voltage }}</div>
                  </div>
                  <div  class="row">
                    <div class="col-6 text-left">Наличие вентилятора</div>
                    <div class="col-6 right">{{ (item.product.has_fan) ? 'да' : 'нет' }}</div>
                  </div>
                  <div v-if="nonempty(item.product.size)" class="row">
                    <div class="col-6 text-left">Размеры (ДхШхВ), мм</div>
                    <div class="col-6 right">{{ item.product.size }}</div>
                  </div>
                  <div v-if="nonempty(item.product.warranty)" class="row">
                    <div class="col-6 text-left">Гарантия, мес.</div>
                    <div class="col-6 right">{{ item.product.warranty }}</div>
                  </div>


                </div>
              </div>
            </b-card-text>
          </b-card-body>

          <!-- Блок кнопок (и часть List) -->
          <div class="item-options text-center">

            <!-- Ценник для List -->
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                  {{ item.price | formatNumber }}
                </h4>
              </div>
            </div>

            <!-- Наличие Grid -->
            <div class="text-right p-1" v-if="itemView =='grid-view'"> В наличии: {{ item.product.count }}</div>

            <!-- Наличие List -->
            <div class="p-1" v-if="itemView =='list-view'"> В наличии: {{ item.product.count }}</div>


            <!-- Кнопки List -->
            <div class="" v-if="itemView =='list-view'">

              <!-- Оригинальный прайс -->
              <div v-if="!isRedirected">
                <div class="row counter-wrapper">
                  <div class="row  col-12 counter-wrapper">
                    <BlSpinButton
                        :value="item.product.counter"
                        :max="item.product.count"
                        @input="changeCounter(item.product, $event)"
                    >
                    </BlSpinButton>
                  </div>
                </div>
                <b-button
                    variant="primary"
                    tag="a"
                    class="btn-cart"
                    @click="addToCart(item.product.id, item.product.counter)"
                >
                  <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"
                  />
                  <span>{{ item.product.isInCart ? 'В корзине' : 'В корзину' }}</span>
                </b-button>
              </div>

              <!-- Редирект с проектов -->
              <div v-if="isRedirected">
                <div class="row counter-wrapper">
                  <div class="row  col-12 counter-wrapper">
                    <BlSpinButton
                        v-if="item.product.count > 0"
                        :value="item.product.counter"
                        :max="item.product.count"
                        @input="changeCounter(item.product, $event)"
                    >
                    </BlSpinButton>
                  </div>
                </div>
                <b-button
                    variant="primary"
                    tag="a"
                    class="btn-cart"
                    @click="addToProject(item.product.id, item.product.counter)"
                    :disabled="item.product.count==0"
                >
                  <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"
                  />
                  <span>{{ "Добавить в проект" }}</span>
                </b-button>
              </div>
            </div>


            <!-- Кнопки Grid -->
            <div class="w-100" v-if="itemView =='grid-view'">

              <!-- Оригинальный прайс -->
              <div v-if="!isRedirected" class="row">
                <div class="row col-12 col-sm-6 counter-wrapper counter-wrapper-gray">
                  <BlSpinButton
                      :value="item.product.counter"
                      :max="999999"
                      @input="changeCounter(item.product, $event)"
                  >
                  </BlSpinButton>
                </div>
                <b-button v-if="itemView =='grid-view'"
                          variant="primary"
                          tag="a"
                          class="btn-cart col-12 col-sm-6"
                          @click="addToCart(item.product.id, item.product.counter)"
                >
                  <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"

                  />
                  <span>{{ item.product.isInCart ? 'В корзине' : 'В корзину' }}</span>
                </b-button>
              </div>

              <!-- Редирект из проектов -->
              <div v-if="isRedirected" class="row">
                <div class="col-12 col-sm-6 counter-wrapper counter-wrapper-gray">
                  <BlSpinButton
                      :value="item.product.counter"
                      :max="999999"
                      @input="changeCounter(item.product, $event)"
                  >
<!--                    Вернуть :max="product.count" -->
                  </BlSpinButton>
                </div>
                <b-button v-if="itemView =='grid-view'"
                          variant="primary"
                          tag="a"
                          class="btn-cart col-12 col-sm-6"
                          @click="addToProject(item.product.id, item.product.counter, item.product)"
                >
                  <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"

                  />
                  <span>{{ "Добавить в проект" }}</span>
                </b-button>
              </div>

            </div>
          </div>
        </b-card>
      </section>
    </b-overlay>

    <!-- Пагинация -->
    <section>
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              :per-page="pagination.perPage"
              v-model="pagination.currentPage"
              :total-rows="pagination.rows"
              @input="changePage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Фильтр -->
    <portal to="content-renderer-sidebar-detached-left">
      <filter-sidebar
          :filters="filters"
          :offset="offset"
          perPage="9"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BOverlay,
  BFormSpinbutton,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import FilterSidebar from './FilterSidebar.vue'
import api from "@/api";
import utils from "@/utils";
import vSelect from 'vue-select';
import BlSpinButton from "@/components/controls/BlSpinButton";

export default {
  directives: {
    Ripple,
  },
  components: {
    BlSpinButton,
    // BSV
    BOverlay,
    BFormSpinbutton,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    vSelect,

    // SFC
    FilterSidebar,
  },
  computed: {
    offset() {
      return (this.pagination.currentPage - 1) * this.pagination.perPage
    },
    isRedirected() {
      if (this.$router.currentRoute.query.zone) {
        return true
      } else return false
    }
  },


  methods: {

    nonempty(value) {
      if (value === undefined) return false;
      if (value === null) return false;
      if (value === '') return false;
      if (value === '0') return false;
      if (value === 0) return false;
      if (value === '0.0') return false;
      if (value === 0.0) return false;
      if (value === '0x0x0') return false
      if (value === '0х0х0') return false
      if (value === '-') return false
      return true;
    },
    changeCounter(product, value) {
      product.counter = value
      this.$forceUpdate()
    },
    // Загрузка данных
    loadProducts(limit, offset, sort, filters, query) {
      const self = this;
      self.dataLoading = true;
      api.store
          .loadProducts(limit, offset, sort, filters, query)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.products = data.result.items;
              self.total = data.result.totalCount;
              self.pagination.rows = data.result.totalCount;
              let index = 0
              for (let item of self.products) {
                if (!item.product.image) {
                  item.product.image = "/static/images/no_photo.png"
                }
                item.product.counter = 1;
              }
              self.dataLoading = false;
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },

    // Пагинация
    changePage() {
      this.loadProducts(this.pagination.perPage, this.offset, this.sort.selected, this.filters, this.searchModel)
      this.searchModel = ''
    },

    // Сортировка
    sortProducts(option) {
      this.sort.selected = option
      this.loadProducts(this.pagination.perPage, this.offset, this.sort.selected, this.filters, this.searchModel)
    },

    // Взаимодействие с продуктами
    addToCart(id, count) {
      const self = this
      api.store.basketAddItem(id, count)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.$store.commit('app/SET_CART', data.result)
              self.dataLoading = false;
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    addToProject(id, count, product) {
      this.$store.dispatch('app.products/pickProduct', {id, count})
      product.counter = 1
      this.$forceUpdate()
      // const self = this
      // api.projects.addToZone(count, id, this.$router.currentRoute.query.zone, this.$router.currentRoute.query.project, this.$router.currentRoute.query.room)
      //     .then((response) => {
      //       const data = response.data;
      //       if (data.error) {
      //         throw "Ошибка";
      //       } else {
      //         this.$router.go(-1)
      //       }
      //     })
      //     .catch((err) => {
      //       const data = err.response.data;
      //       if (data && data.error) {
      //         utils.notificateError(self, data.message, data.errorCode);
      //       }
      //     });
    },
    loadFilters() {
      const self = this;
      api.store.getAllProductFilters()
          .then(response => {
            const data = response.data;
            if (data && data.result) {
              //Categories
              this.filterOptions.categories = data.result.categories;
              // //Categories
              // this.filterOptions.categories = [];
              // for (let category of data.result.categories) {
              //   this.filterOptions.categories.push(category.name);
              // }

              //brands
              this.filterOptions.brands = data.result.brands;
              //voltage
              this.filterOptions.voltages = data.result.voltages;
              //grade_of_waterproofment
              this.filterOptions.ips = data.result.grade_of_waterproofment;
            }
          })

    }
  },
  watch: {
    'pagination.perPage'(perPage){
      this.changePage()
    },
    filters: {
      deep: true,
      handler(val) {
        const self = this;
        self.dataLoading = true;
        this.loadProducts(this.pagination.perPage, this.offset, this.sort.selected, this.filters, this.searchModel)
        // api.store
        //     .loadProducts(9, this.offset, this.sort.selected, this.filters, this.searchModel)
        //     .then((response) => {
        //       const data = response.data;
        //       if (data.error) {
        //         throw "Ошибка";
        //       } else {
        //         self.products = data.result.items;
        //         self.total = data.result.totalCount;
        //         self.pagination.rows = data.result.totalCount;
        //         for (let product of self.products) {
        //           if (!product.image){
        //             product.image = "/static/images/no_photo.png"
        //           }
        //         }
        //         self.dataLoading = false;
        //       }
        //     })
        //     .catch((err) => {
        //       const data = err.response.data;
        //       if (data && data.error) {
        //         utils.notificateError(self, data.message, data.errorCode);
        //       }
        //     });
      },
    }
  },
  mounted() {
    this.loadProducts(this.pagination.perPage, this.offset, this.sort.selected, this.filters)
    this.loadFilters();
  },
  data() {
    return {
      // Core
      dataLoading: false,
      itemView: 'grid-view',
      itemViewOptions: [
        {icon: 'GridIcon', value: 'grid-view'},
        {icon: 'ListIcon', value: 'list-view'},
      ],

      // Сортировка
      sort: {
        options: [
          {text: 'По умолчанию', value: ''},
          {text: 'По возрастанию цены', value: 'price'},
          {text: 'По убыванию цены', value: '-price'},
        ],
        selected: {text: 'По умолчанию', value: ''},
      },

      // Поиск
      searchModel: '',

      // Пагинация
      pagination: {
        perPage: 20,
        currentPage: 1,
        rows: 1,
        perPageOptions: [
            20,
            50,
            100
        ]
      },

      // Фильтры
      filters: {
        brands: [],
        categories: [],
        series: [],
        ratings: null,
        page: 1,
        perPage: 18,
        power: {
          value: 0,
          options: {
            min: 0,
            max: 1000

          }
        },
        price: {
          value: [0, 10000],
          options: {
            min: 0,
            max: 10000,
            marks: [0, 5000, 10000],
          }
        },
        voltages: [],
        ip: [],
      },
      filterOptions: {
        categories: [

        ],
        brands: [

        ],

        voltages: [

        ],
        ips: [

        ],
        warranty: [

        ],
      },

      // Прайс-лист
      products: [],
      total: 0,
    }

  },
  setup() {
    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()
    return {
      mqShallShowLeftSidebar,
    }
  },


}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.text-right {
  width: 100%;
  text-align: right;
}

.counter-wrapper {
  margin: 0;
  align-items: center;
  justify-content: center;
}

.counter-wrapper-list {
  min-width: 150px;
}

.counter-wrapper-gray {
  background: #F9F8F6;
}

.counter-btn {
  max-width: 100px;
}

.counter-text {
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  font-weight: bold;

}

.grid-product {
  width: 100%;
  justify-content: space-between;
  margin: 0;
}

.btn-ultra {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
}

.text-m0 {
  margin: 0;
  padding: 0;
}

.per-page-selector {
  width: 250px;
}

.item-img {
  padding: 5px;
  padding-top: 0;

  img {
    max-height: 25vh;
    object-fit: contain;
  }
}

.product-img {
  margin-top: -0.5rem;
}

.input-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 600px;
  width: 100%;
  height: 60px;

  &:before {
    position: absolute;
    top: 8px;
    right: 20px;
    color: #ECEFF1;
    font-size: 20px;
    content: "▲";
    pointer-events: none;
    display: none;
  }

  &:after {
    position: absolute;
    bottom: 8px;
    right: 20px;
    color: #ECEFF1;
    font-size: 20px;
    content: "▼";
    pointer-events: none;
    display: none;
  }

  .input {
    box-sizing: border-box;
    display: block;
    margin: 0 auto 40px;
    border: none;
    border-radius: 10px;
    padding: 20px 60px 20px 20px;
    width: 100%;
    height: 60px;
    font: 20px "Avenir Next";
    box-shadow: 0 0 2px rgba(0, 0, 0, .5) inset;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }

    &::-webkit-inner-spin-button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      transform: scale(5);
      transform-origin: right center;
      opacity: 0;
      cursor: pointer;
    }

    &::-webkit-contacts-auto-fill-button {
      opacity: 0;
    }
  }
}


</style>
