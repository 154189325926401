<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
          class="sidebar-shop"
          :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Фильтрация
            </h6>
          </b-col>
        </b-row>
        <b-card>

          <!-- Категории -->
          <div class="product-categories">
            <h6 class="filter-title">
              Категории товара
            </h6>
            <bl-category-tree
                :categories="filterOptions.categories"
                :selected="filters.categories"
                @change="changeCategories($event)"
            ></bl-category-tree>

            <!--            <b-form-checkbox-->
            <!--              v-for="category in "-->
            <!--            ></b-form-checkbox>-->
            <!--            <b-form-checkbox-group-->
            <!--                v-model="filters.categories"-->
            <!--                stacked-->
            <!--                :options="Object.keys(filterOptions.categories)"-->
            <!--            />-->
          </div>

          <!--          &lt;!&ndash; Серии &ndash;&gt;-->
          <!--          <div v-if="filters.categories.length > 0" class="product-categories">-->
          <!--            <h6 class="filter-title">-->
          <!--              Серии-->
          <!--            </h6>-->
          <!--            <b-form-checkbox-group-->
          <!--                v-model="filters.series"-->
          <!--                stacked-->
          <!--                :options="allAvailableSeries"-->
          <!--            />-->
          <!--          </div>-->

          <!--          &lt;!&ndash; Производитель &ndash;&gt;-->
          <!--          <div class="brands">-->
          <!--            <h6 class="filter-title">-->
          <!--              Бренды-->
          <!--            </h6>-->
          <!--            <b-form-checkbox-group v-model="filters.brands"-->
          <!--                                   :options="filterOptions.brands"-->
          <!--                                   stacked-->
          <!--            ></b-form-checkbox-group>-->
          <!--          </div>-->

          <!--          &lt;!&ndash; По цене &ndash;&gt;-->
          <!--          <div class="price-slider" style="margin-bottom: 2rem">-->
          <!--            <h6 class="filter-title">-->
          <!--              По цене-->
          <!--            </h6>-->
          <!--            <vue-slider-->
          <!--                v-model="filters.price.value"-->
          <!--                v-bind="filters.price.options"-->
          <!--                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
          <!--                :marks="filters.price.options.marks"-->
          <!--                :contained="true"-->
          <!--            />-->
          <!--          </div>-->

          <!--          &lt;!&ndash;          &lt;!&ndash; По мощности &ndash;&gt;&ndash;&gt;-->
          <!--          &lt;!&ndash;          <div class="price-slider">&ndash;&gt;-->
          <!--          &lt;!&ndash;            <h6 class="filter-title">&ndash;&gt;-->
          <!--          &lt;!&ndash;              Мощность&ndash;&gt;-->
          <!--          &lt;!&ndash;            </h6>&ndash;&gt;-->
          <!--          &lt;!&ndash;            <vue-slider&ndash;&gt;-->
          <!--          &lt;!&ndash;                v-model="filters.power.value"&ndash;&gt;-->
          <!--          &lt;!&ndash;                v-bind="filters.power.options"&ndash;&gt;-->
          <!--          &lt;!&ndash;                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"&ndash;&gt;-->
          <!--          &lt;!&ndash;            />&ndash;&gt;-->
          <!--          &lt;!&ndash;          </div>&ndash;&gt;-->

          <!--          &lt;!&ndash; По напряжение &ndash;&gt;-->
          <!--          <div class="product-categories">-->
          <!--            <h6 class="filter-title">-->
          <!--              По напряжению-->
          <!--            </h6>-->
          <!--            <b-form-checkbox-group-->
          <!--                v-model="filters.voltages"-->
          <!--                stacked-->
          <!--                :options="filterOptions.voltages"-->
          <!--            />-->
          <!--          </div>-->

          <!--          &lt;!&ndash; По степени влагозащиты &ndash;&gt;-->
          <!--          <div class="product-categories">-->
          <!--            <h6 class="filter-title">-->
          <!--              По степени влагозащиты-->
          <!--            </h6>-->
          <!--            <b-form-checkbox-group-->
          <!--                v-model="filters.ips"-->
          <!--                class="categories-radio-group"-->
          <!--                stacked-->
          <!--                :options="filterOptions.ips"-->
          <!--            />-->
          <!--          </div>-->

          <!--          &lt;!&ndash;          &lt;!&ndash; По гарантии &ndash;&gt;&ndash;&gt;-->
          <!--          &lt;!&ndash;          <div class="product-categories">&ndash;&gt;-->
          <!--          &lt;!&ndash;            <h6 class="filter-title">&ndash;&gt;-->
          <!--          &lt;!&ndash;              По напряжению&ndash;&gt;-->
          <!--          &lt;!&ndash;            </h6>&ndash;&gt;-->
          <!--          &lt;!&ndash;            <b-form-radio-group&ndash;&gt;-->
          <!--          &lt;!&ndash;                v-model="filters.warranty"&ndash;&gt;-->
          <!--          &lt;!&ndash;                class="categories-radio-group"&ndash;&gt;-->
          <!--          &lt;!&ndash;                stacked&ndash;&gt;-->
          <!--          &lt;!&ndash;                :options="filterOptions.warranty"&ndash;&gt;-->
          <!--          &lt;!&ndash;            />&ndash;&gt;-->
          <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
          <!--          <b-button-->
          <!--              variant="primary"-->
          <!--              style="margin-top: 2.0rem"-->
          <!--              @click="resetFilters"-->

          <!--          >-->
          <!--            <span>Сбросить все фильтры</span>-->
          <!--          </b-button>-->
        </b-card>
      </div>
    </div>

    <div
        class="body-content-overlay"
        :class="{'show': mqShallShowLeftSidebar}"
        @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BLink, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import BlCategoryTree from "@/components/views/price-list/list/BlCategoryTree";

export default {
  components: {
    BlCategoryTree,
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
    BFormCheckboxGroup,
    BButton,

    // 3rd Party
    VueSlider,
  },
  props: ['filters', 'filterOptions', 'mqShallShowLeftSidebar'],
  computed: {
    allAvailableSeries() {
      let series = [];
      for (let [categoryName, categorySeries] of Object.entries(this.filterOptions.categories)) {
        if (this.filters.categories.includes(categoryName)) {
          series = series.concat(categorySeries)

        }

      }
      return series
    }
  },
  watch: {
    'allAvailableSeries': function (newValue, oldValue) {
      const forRemove = []
      for (let selectedSeries of this.filters.series) {
        if (!newValue.includes(selectedSeries)) {
          forRemove.push(selectedSeries)
        }
      }
      for (let series of forRemove) {
        this.filters.series.splice(this.filters.series.indexOf(series), 1)
      }
    }
  },
  methods: {
    changeCategories(changes){
      // Добавить выбранные, но при этом избегать дубликатов
      const addition = changes[0].filter(item => !this.filters.categories.includes(item))
      console.log('addition: ', addition)
      this.filters.categories.push(...addition)
      console.log('this.filters.categories: ', this.filters.categories)

      // Убрать лишнеее
      this.filters.categories = this.filters.categories.filter(item => !changes[1].includes(item))

      console.log('changes: ', changes)
      console.log('this.filters.categories: ', this.filters.categories)
    },

    resetFilters() {
      this.filters.brands = [];
      this.filters.categories = [];
      this.filters.voltages = [];
      this.filters.ips = [];
      this.filters.price.value = [0, 10000];

    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.filter-title {
  margin-top: 1rem !important;
}
</style>
