import { render, staticRenderFns } from "./ProductsList.vue?vue&type=template&id=107171de&scoped=true&"
import script from "./ProductsList.vue?vue&type=script&lang=js&"
export * from "./ProductsList.vue?vue&type=script&lang=js&"
import style0 from "./ProductsList.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ProductsList.vue?vue&type=style&index=1&id=107171de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107171de",
  null
  
)

export default component.exports