<template>
  <div :style="`padding-left: ${paddingLeft}px`">
    <div class="d-flex flex-column">
      <div
          v-for="category in categories"
          class="flex-column d-flex"
          style="cursor:pointer;"
          @click="opened.includes(category.id) ? opened.splice(opened.indexOf(category.id), 1) : opened.push(category.id); $event.stopPropagation(); "
      >
        <div class="d-flex flex-row">
          <div v-if="category.children.length > 0"
               style="min-width: 18px; min-height: 18px; display: flex; justify-content: center; align-items: center; cursor: pointer"

          >
            <feather-icon icon="ChevronRightIcon"/>
          </div>
          <div v-else
               style="min-width: 18px; min-height: 18px; display: flex; justify-content: center; align-items: center; cursor: pointer"

          ></div>
          <b-form-checkbox
              :checked="selected.includes(category.name)"
              @input="handleToogle($event, category)"
              @click="$event.stopPropagation(); $event.preventDefault();"

          ></b-form-checkbox>

          <div
              style="flex-grow: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              @click="handleToogle(!selected.includes(category.name), category)"
          >
            {{ category.name }}

          </div>
        </div>
        <bl-category-tree :selected="selected" v-if="opened.includes(category.id)" :categories="category.children"
                          :padding-left="paddingLeft+10"

                          @change="$emit('change', [$event[0], [...$event[1], category.name]]);"
        >

        </bl-category-tree>
      </div>

    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BLink, BFormCheckboxGroup, BButton, BFormCheckbox
} from 'bootstrap-vue'
import VueSlider from "vue-slider-component";

export default {
  components: {
    BFormCheckbox,
  },
  name: "BlCategoryTree",
  props: {
    categories: {
      default: [],
    },
    selected: {
      default: [],
    },
    paddingLeft: {
      default: 0,
    }
  },
  data: () => ({
    opened: [],
    value: 'not_accepted',
  }),
  methods: {
    childrenCategories(category) {
      if (!category) return []
      const subCategories = []
      for (let children of category.children) {
        subCategories.push(children.name)
        subCategories.push(...this.childrenCategories(children))
      }
      return subCategories
    },
    handleToogle(event, category) {
      console.log('Handle toggle: ', event, category)
      if (event) {
        this.$emit('change', [[category.name], this.childrenCategories(category)])
        this.opened.includes(category.id) ? this.opened.splice(this.opened.indexOf(category.id), 1) : this.opened.push(category.id)
      } else {
        this.$emit('change', [[], [category.name]])
      }

    }
  }
}
</script>

<style scoped>

</style>
